
import { mapStores } from "pinia"

import { AnalyticsEventPageId } from "@evercam/shared/types"
import { AnalyticsEvent } from "@evercam/shared/types/analytics"
import { generateUuid } from "@evercam/shared/utils"
import { useAccountStore } from "@/stores/account"

import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"
import SitePlannerViewer from "@/components/sitePlanner/SitePlannerViewer"
import SitePlanCard from "@/components/sitePlanner/SitePlanCard.vue"
import SupportMessage from "@/components/SupportMessage"

export default {
  name: "SitePlannerProjectList",
  meta: {
    pageId: AnalyticsEventPageId.SitePlanner,
  },
  components: {
    SitePlannerViewer,
    SitePlanCard,
    EvercamLoadingAnimation,
    SupportMessage,
  },
  data: () => ({
    sitePlansList: [],
    isFetchingSitePlans: false,
    selectedSitePlanId: null,
    search: "",
    searchDebounce: null,
    searchDebounceTimeOut: 300,
    currentPage: 1,
    pageSize: 12,
    isAddingNewPlan: false,
  }),
  computed: {
    ...mapStores(useAccountStore),
    displayedSitePlansList() {
      const listToDisplay = this.filteredSitePlansList.slice(
        (this.currentPage - 1) * this.pageSize,
        this.currentPage * this.pageSize
      )

      return listToDisplay
    },
    filteredSitePlansList() {
      const filteredList = this.search
        ? this.filterSitePlans()
        : [...this.sitePlansList]

      // Sort by most recent
      filteredList.sort(
        (a, b) => new Date(b.createdDate) - new Date(a.createdDate)
      )

      return filteredList
    },
    totalPages() {
      const pages = Math.ceil(this.filteredSitePlansList.length / this.pageSize)

      return pages
    },
  },
  watch: {
    "$route.query.sitePlanId": {
      handler(newValue) {
        if (!newValue) {
          this.clearSelectionAndFilter()
        } else {
          this.selectedSitePlanId = newValue
        }
      },
      immediate: true,
    },
    search() {
      this.currentPage = 1
    },
    selectedSitePlanId(newValue) {
      if (newValue) {
        this.sitePlansList = []
      } else {
        this.fetchSitePlansList()
      }
    },
  },
  async mounted() {
    this.$analytics.saveEvent(AnalyticsEvent.PageView)
    await this.fetchSitePlansList()
  },
  onBeforeUnmount() {
    this.sitePlansList = []
    this.clearSelectionAndFilter()
    this.isAddingNewPlan = false
  },
  methods: {
    async onAddNewSitePlanClicked() {
      const id = await generateUuid()
      this.isAddingNewPlan = true
      this.selectedSitePlanId = id
    },
    async onBackToListClicked() {
      this.clearSelectionAndFilter()
      this.isAddingNewPlan = false
      await this.fetchSitePlansList()
    },
    onSitePlanCardClicked(sitePlanListItem) {
      this.selectedSitePlanId = sitePlanListItem.projectId
    },
    clearSelectionAndFilter() {
      this.search = ""
      this.selectedSitePlanId = null
    },
    async fetchSitePlansList() {
      try {
        this.isFetchingSitePlans = true

        // Fetch the projects object from the firebase database
        const result = await fetch(
          `${this.$config.public.firebaseDbLink}/data/planner-list.json`,
          {
            method: "GET",
          }
        )

        // Parse the result to a JSON object
        const sitePlansObject = await result.json()

        if (!sitePlansObject) {
          return
        }

        // Convert projectsObject to a list
        this.sitePlansList =
          this.sitePlansListObjectToSitePlansList(sitePlansObject)
      } catch (e) {
        this.$notifications.error({
          text: "Error fetching site plans list.",
          error: e,
        })
      } finally {
        this.isFetchingSitePlans = false
      }
    },
    filterSitePlans() {
      return this.sitePlansList.filter(
        (sitePlan) =>
          sitePlan.projectName
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          sitePlan.projectId
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          sitePlan.projectLocation
            .toLowerCase()
            .includes(this.search.toLowerCase())
      )
    },
    sitePlansListObjectToSitePlansList(sitePlansObject) {
      if (!sitePlansObject) {
        return []
      }

      /**
       * Each field of projectsObject is a project
       * - So we map each field to a project object
       * - And add the sessionId to the project object
       */
      return Object.keys(sitePlansObject).map((projectId) => {
        return {
          ...sitePlansObject[projectId], // Project object properties
          projectId,
        }
      })
    },
  },
}
