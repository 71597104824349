
import Vue, { PropType } from "vue"

import { EMapTypes } from "@evercam/shared/types"

export default Vue.extend({
  name: "SitePlanCard",
  props: {
    sitePlanListItem: {
      type: Object,
      default: () => ({}),
    },
    onSitePlanCardClicked: {
      type: Function as PropType<(sitePlanListItem: object) => void>,
      default: () => () => {},
    },
  },
  data() {
    return {
      url: "" as string,
      isActive: false as boolean,
      relativeCreationDate: "" as string,
      formattedCreationDate: "" as string,
    }
  },
  computed: {
    camerasCountColor(): string {
      return this.$vuetify.theme.dark ? "#10131b" : "grey darken-1"
    },
    hasEllipsis() {
      let el = this.$refs?.ellipsis?.$el
      if (el) {
        return el.offsetWidth < el.scrollWidth
      } else {
        return false
      }
    },
    isMapTypeBlank() {
      return this.sitePlanListItem.projectMapType === EMapTypes.Blank
    },
  },
  mounted() {
    // Set the thumbnail
    this.url =
      this.sitePlanListItem.thumbnailUrl ??
      require("~/assets/img/waiting_for_activation.jpg")

    // Set the creation date
    this.relativeCreationDate = this.getRelativeDate(
      this.sitePlanListItem.createdDate
    )
    this.formattedCreationDate = this.formatDateInLocalTimezone(
      this.sitePlanListItem.createdDate
    )
  },
  methods: {
    onError() {
      this.url = require("~/assets/img/waiting_for_activation.jpg")
      this.$emit("error", this.url)
    },
    formatDateInLocalTimezone(date) {
      if (!date) {
        return ""
      }

      return `${this.$moment
        .utc(date)
        .local()
        .format("MMMM Do YYYY, h:mm:ss a")}`
    },
    getRelativeDate(date) {
      if (!date) {
        return ""
      }

      return `Added ${this.$moment.utc(date).local().fromNow()}`
    },
    getHoverStyle(hover = false) {
      if (this.$vuetify.theme.dark) {
        return `${hover ? "hovered" : ""} lighten-${hover ? "2" : "1"}`
      }

      return `${hover ? "hovered" : ""} darken-${hover ? "2" : "1"}`
    },
    getIconColor(enabledFeature) {
      if (enabledFeature) {
        return this.$vuetify.theme.currentTheme.on_background
      }

      return this.$vuetify.theme.dark ? "rgba(218,221,227,0.3)" : "#757575"
    },
  },
})
